import { Dispatch } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import ActionsList from './Actions.list'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import HTTPClient from '../services/HTTPClient'
import { actionCoreApi } from 'core/utils/api'
import { generateRandomId } from 'shared/helpers/utils'
import { showNotification } from './Notifications.actions'
import { defaultListParams } from '../utils/constants'
import { IAction, IFilters, ISort } from './Actions.interfaces'
import { IAccessScope, IUser, NotificationTypes } from 'reducers/Reducers.interfaces'
import { ActionType, dispatcherActionType, IErrorData } from 'core/utils/interfaces'
import { actionErrorNotifier } from 'core/utils/helpers'
import { IServiceUserFormValues } from 'secure-pages/service-user/ServiceUser.types'
import { verify } from '../../public-pages/login/Login.actions'
import { RouterConfig } from 'core/utils/constants'

export const fetchUsersList = (
  params: ISort = defaultListParams,
  filters?: IFilters
): ActionType =>
  actionCoreApi(
    ActionsList.FetchUsersList,
    HTTPUrlHelper.getLimitedUsersAPI(params, filters)
  )

export const fetchUserById = (id: string): ActionType => actionCoreApi(
    ActionsList.FetchUserRequest,
    HTTPUrlHelper.getUserByIdAPI(id)
  )

export function updateAgreement (id: string, locale: string, stateVal: string) {
  return async (dispatch: ThunkDispatch<any, any, IAction>): Promise<void> => {
    dispatch({ type: ActionsList.UpdateUserAgreementRequest })
    const payload = {
      locale: locale
    }
    const URL: string = HTTPUrlHelper.updateAgreement(id)
    const { data, status } = await HTTPClient.PUT(URL,payload)

    if (data && status === 200) {
      dispatch({ type: ActionsList.UpdateUserAgreementSuccess, payload: data })
       if (stateVal != null) {
           let lang = ""
           if (locale) {
               lang = locale?.split('_')[0]
           }
           verify(data.user_name, stateVal, lang)
       }
        if (stateVal === null) {
            window.location.href = RouterConfig.accounts.table
        }
    }

    if (data.error) {
      dispatch({ type: ActionsList.UpdateUserAgreementFailure })
    }
  }
}

export function createUser(formData) {
  return async (dispatch: ThunkDispatch<any, any, IAction>): Promise<void> => {
    dispatch({ type: ActionsList.CreateUserRequest })

    const URL: string = HTTPUrlHelper.getUserAPI()
    const { data, status } = await HTTPClient.POST(URL, formData)

    if (status === 201) {
      dispatch({ type: ActionsList.CreateUserSuccess })
      dispatch(
        showNotification({
          id: generateRandomId(),
          message: 'notifications.users.createUserSuccess',
          type: NotificationTypes.success
        })
      )
    }

    if (data.error) {
      dispatch({ type: ActionsList.CreateUserFailure })
      dispatch(
        showNotification({
          id: generateRandomId(),
          title: 'notifications.users.createUserTitle',
          message: `notifications.exceptions.${data.error.status}`,
          type: NotificationTypes.error
        })
      )
    }
  }
}

export const createServiceUser = (formData): dispatcherActionType => actionErrorNotifier(
  actionCoreApi(
    ActionsList.CreateUserRequest,
    HTTPUrlHelper.getServiceUserAPI(),
    'POST',
    formData
  ), true,
  {
    title: '',
    message: 'notifications.users.createUserSuccess'
  }
)

export const editServiceUser = (formData:IServiceUserFormValues, id: string ): dispatcherActionType => actionErrorNotifier(
  actionCoreApi(
    ActionsList.EditUserRequest,
    HTTPUrlHelper.getEditServiceUserAPI(id),
    'PUT',
    formData
  ), true,
  {
    title: '',
    message: 'notifications.users.updateServiceUserSuccess'
  }
)

export const updateManagerPreference = (userId: string, appUrl: string ): dispatcherActionType => actionErrorNotifier(
  actionCoreApi(
    ActionsList.EditUserRequest,
    HTTPUrlHelper.updateManagerPreference(userId, appUrl),
    'PUT'
  ), true,
  {
    title: '',
    message: 'notifications.users.updateDefaultApplication'
  }
)


export function enableDisableServiceUser (id: string, payload: any) {
  return async (dispatch: ThunkDispatch<any, any, IAction>): Promise<void> => {
    dispatch({ type: ActionsList.EnableDisableServiceUser })
  
    const URL: string = HTTPUrlHelper.getEnableDisableServiceUserAPI(id)
    const { data, status } = await HTTPClient.PUT(URL, payload)
    if (status === 200) {
      const msg = payload.enabled ? 'notifications.users.serviceUserEnable' : 'notifications.users.serviceUserDisabled'
      dispatch(showNotification({
        id: generateRandomId(),
        message: msg,
        type: NotificationTypes.success
      }))
    }

    if (data.error) {
      dispatch(showNotification({
        id: generateRandomId(),
        title: `notifications.exceptions.${data.error.status}`,
        message: `notifications.exceptions.${data.error.message}`,
        type: NotificationTypes.error
      }))
    }
  }
}

export function editUser (id: string, formData, editPermission: boolean) {
  return async (dispatch: ThunkDispatch<any, any, IAction>): Promise<void> => {
    dispatch({ type: ActionsList.EditUserRequest })

    const URL: string = HTTPUrlHelper.getUserByIdAPI(id)
    const { data, status } = await HTTPClient.PUT(URL, formData)

    if (data && status === 200 && !editPermission) {
      dispatch({ type: ActionsList.EditUserSuccess })
      dispatch(
        showNotification({
          id: generateRandomId(),
          message: 'notifications.users.editUserSuccess',
          type: NotificationTypes.success
        })
      )
    }
    if (data && status === 200 && editPermission) {
      dispatch({ type: ActionsList.EditUserSuccess })
      dispatch(
        showNotification({
          id: generateRandomId(),
          message: 'notifications.users.permissionEdited',
          type: NotificationTypes.success
        })
      )
    }

    if (data.error) {
      dispatch({ type: ActionsList.EditUserFailure })
      dispatch(
        showNotification({
          id: generateRandomId(),
          title: 'notifications.users.editUserTitle',
          message: `notifications.exceptions.${data.error.message}`,
          type: NotificationTypes.error
        })
      )
    }
  }
}

export function deleteUser(ids: string[]): ActionType {
  return async (dispatch: Dispatch<IAction>): Promise<void[]> => {
    return Promise.all(
      ids.map((id) => {
        return actionCoreApi<IAction>(
          ActionsList.DeleteUser,
          HTTPUrlHelper.getUserByIdAPI(id),
          'DELETE'
        )(dispatch)
          .then(() => {
            dispatch(
              showNotification({
                id: generateRandomId(),
                message: 'notifications.users.deleteUserSuccess',
                type: NotificationTypes.success
              })
            )
          })
          .catch((error: IErrorData) => {
            dispatch(
              showNotification({
                id: generateRandomId(),
                title: `notifications.exceptions.${error.status}`,
                message: error.message,
                type: NotificationTypes.error,
                translateMessage: false
              })
            )
          })
      })
    )
  }
}

export function resendVerificationLink(userId: string, lockedUser: boolean) {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    return actionCoreApi<IAction>(
      ActionsList.ResendVerificationLink,
      HTTPUrlHelper.resendPasswordResetLink(userId),
      'PUT'
    )(dispatch)
      .then(data => {
        dispatch(
          showNotification({
            id: generateRandomId(),
            message: lockedUser ? data.message : 'notifications.users.resendVerificationLinkSuccess',
            type: NotificationTypes.success
          })
        )
      })
      .catch((error: IErrorData) => {
        dispatch(
          showNotification({
            id: generateRandomId(),
            title: `notifications.exceptions.${error.status}`,
            message: error.message,
            type: NotificationTypes.error,
            translateMessage: false
          })
        )
      })
  }
}

export function sendSetupCacBasedLoginEmail(userId: string) {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    return actionCoreApi<IAction>(
      ActionsList.ResendVerificationLink,
      HTTPUrlHelper.sendSetupCacBasedLoginEmail(userId),
      'PUT'
    )(dispatch)
      .then(() => {
        dispatch(
          showNotification({
            id: generateRandomId(),
            message: 'notifications.users.cacEmailSentSuccessfully',
            type: NotificationTypes.success
          })
        )
      })
      .catch((error: IErrorData) => {
        dispatch(
          showNotification({
            id: generateRandomId(),
            title: `notifications.exceptions.${error.status}`,
            message: error.message,
            type: NotificationTypes.error,
            translateMessage: false
          })
        )
      })
  }
}


export function resetMfaLink(id: string, lockedUser: boolean) {
  return async (dispatch: ThunkDispatch<any, any, IAction>): Promise<void> => {
    dispatch({ type: ActionsList.ResendMfa })

    const URL: string = HTTPUrlHelper.mfaLink(id)
    const { data, status } = await HTTPClient.DELETE(URL)

    if (data && status === 200) {
      dispatch({ type: ActionsList.ResendMfa })
      dispatch(
        showNotification({
          id: generateRandomId(),
          message: lockedUser ? 'notifications.users.unlockUserEmailSent' : 'notifications.users.resendMfaLinkSuccess',
          type: NotificationTypes.success
        })
      )
    }

    if (data.error) {
      dispatch({ type: ActionsList.EditUserFailure })
      dispatch(
        showNotification({
          id: generateRandomId(),
          title: 'notifications.users.resendMfaLinkTitle',
          message: `notifications.exceptions.${data.error.message}`,
          type: NotificationTypes.error
        })
      )
    }
  }
}
export function createStdUser(userId: string) {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    return actionCoreApi<IAction>(
      ActionsList.CreateStdUser,
      HTTPUrlHelper.createStdUserLink(userId),
      'PUT'
    )(dispatch)
      .then(() => {
        dispatch(
          showNotification({
            id: generateRandomId(),
            message: 'notifications.users.inviteUserToStdLogin',
            type: NotificationTypes.success
          })
        )
      })
      .catch((error: IErrorData) => {
        dispatch(
          showNotification({
            id: generateRandomId(),
            title: `notifications.exceptions.${error.status}`,
            message: error.message,
            type: NotificationTypes.error,
            translateMessage: true
          })
        )
      })
  }
}

export function resendSsoEmail(userId: string) {
      return async (dispatch: Dispatch<IAction>): Promise<void> => {
        return actionCoreApi<IAction>(
          ActionsList.CreateStdUser,
          HTTPUrlHelper.resendSsoEmail(userId),
          'PUT'
        )(dispatch)
          .then(() => {
            dispatch(
              showNotification({
                id: generateRandomId(),
                message: 'notifications.users.ssoEmailSent',
                type: NotificationTypes.success
              })
    )
    })
    .catch((error: IErrorData) => {
            dispatch(
              showNotification({
                id: generateRandomId(),
                title: `${error.status}`,
                message: `${error.message}`,
                type: NotificationTypes.error,
                translateMessage: true
              })
    )
    })
    }
}


export const disableUserEdit = (user: IUser | undefined) : boolean => {
  if (user) {
    return user.access_scope === IAccessScope.account && ( user.accounts.length === 0 || user.accounts.filter(account => account.id === user.primary_account_id) === undefined)
  }
  return false
}

