import { DTACreateTimeout } from './dta.constants'
import { APIClient } from './DTAClient'

//dev license
// const license =
//   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ2ZXJzaW9uIjoiMS4wLjAiLCJjb21wYW55IjoiRGlnaUNlcnQsIEluYy4iLCJhbGxvd2VkT3JpZ2lucyI6WyIxMjcuMC4wLjEiLCJsb2NhbGhvc3QiLCIxOTIuMTY4LjEuMzkiXSwiaWQiOiJkYjg3OGUyYi0xM2EwLTQ3MzgtYjZlNy02MmNlNjY0MDUyNDQiLCJpYXQiOjE2NjU2MTkyMDAwMDAsImlzcyI6Imh0dHBzOi8vdHJ1c3Rhc3Npc3RhbnQuZGlnaWNlcnQuY29tIiwic3ViIjoiTGljZW5zZSBDb2RlIG9mIERpZ2lDZXJ0IFRydXN0IEFzc2lzdGFudCBKYXZhIFNESyIsImV4cCI6MTc2MDMxMzU5OTAwMH0.gqAfTO9ojVKdzr4QrUK_w2I811P6ai0r8IMVZsQhiiBls6_M3Y7Vy6adnNFOI4VZXZz7jhEXlXDG_jwAwE4D9L8DOwt2SZxF_P1F97GQhnj00wpHcVRlPv5nVEUp5L3IzzsOO94e3MCgZkdW0cZV6hNBFCNTrAPWtrZ3s2w5pgNnclwsf9bOCGvHP42A19lTvYlMLdN9ge0FE48lAYpy58qzoyb30ZrJNVY8p5zlJpwj2J6J4J36X2eG0rrc3AJrKySl328o2MsOgtA1LFq059rC4ELYhL2A0aL5bLpBoJcOYX-YvM43dyp_RCcAtHt7qgPW4xReH_3Z5UctOJij6A'

// prod license
const license =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ2ZXJzaW9uIjoiMS4wLjAiLCJjb21wYW55IjoiRGlnaUNlcnQsIEluYy4iLCJhbGxvd2VkT3JpZ2lucyI6WyIqLnN5bWF1dGguY29tIiwiKi5iYnRlc3QubmV0IiwiKi5kaWdpY2VydC5jb20iLCIqLnF1b3ZhZGlzZ2xvYmFsLmNvbSIsIioub25lLmRpZ2ljZXJ0LmNvLmpwIiwiZGNvbmUuY2x1c3Rlci5sb2NhbCIsImRjb25lLndvcmsiLCIqLmJ0bXBraS5jb20iLCIqLnNtYXJ0ZGNjLmNvLnVrIiwiKi5kY2NpbnRlcmZhY2UuY28udWsiLCIqLnZpbm8uYnQuY28udWsiLCIqLmJ0LmNvbSIsIiouYnQucHNuLXotc2VydmljZS5uZXQiLCIqLmFncmliYW5rLmNvbS52biJdLCJpZCI6IjkxYTQwMWUwLTg2NGUtNDViZC04MjEzLWJlOGZmMDQ3MDg2NCIsImlhdCI6MTY3NTM4MjQwMDAwMCwiaXNzIjoiaHR0cHM6Ly90cnVzdGFzc2lzdGFudC5kaWdpY2VydC5jb20iLCJzdWIiOiJMaWNlbnNlIENvZGUgb2YgRGlnaUNlcnQgVHJ1c3QgQXNzaXN0YW50IEphdmEgU0RLIiwiZXhwIjoxNzcwMDc2Nzk5MDAwfQ.ReFh2NlGmIw2dnqwYUi9RQRpaNNed_DJSFkVPdoLM58MSCtX2Sb9IksxHK253EDyzVwG2nKgXIBmgxbBQuczygtTSSQeMMKoXqfb0pMHWjWYp208qL5oQsvlTCpJjTfezQb4LkanZBHLv7JGV3uNXcQKWRNB6Hh6jIvPmpbEj2f5jgGaEL5essq2tIuZwiXQXjF7bsR1TxtAbkSki_nAthZs8RIRpgdqdYqvV0raz87gGe2SDJ9mCNbhq3PxMcnwUUIVQ_d01Uwqxhjsxv0xSKJbKdsvzGNRaxIerVm04-NXmf-P1ht5CShkDeSWyR0lnNUE7btVAdv9dyGq2utJWQ'

export class PKIClientInstance {
  private static instance: APIClient | null = null

  public static async get(): Promise<APIClient | null> {
    let instance

    if (this.instance) {
      const isCurrentInstanceRunning = await this.instance.isRunning()

      if (isCurrentInstanceRunning) {
        return Promise.resolve(this.instance)
      }
    }

    instance = await APIClient.create(license, DTACreateTimeout)

    this.instance = instance

    return this.instance
  }
}
