import { IEnrollmentFieldsResponse, ISeatProfile, TypeInputList } from 'public-pages/enrollment/Enrollment.interfaces'
import { ISigningProvider } from 'secure-pages/tenants/create-account-signing-client/AccountSigningClient.types';

export interface ILocales{
  locales : ILocale[]
}

export interface ILocale {
  id: string
  name: string
}

export interface IAuth {
  isAuth: boolean
  accessToken: string
  expiresAt: string
  licenseStatus: string
   isHubVisible: boolean
  redirectUrl: string
}

export interface IUsers {
  items: IUser[]
  total: number
  isLoading: boolean
  isEditing: boolean
}

export enum IAccessScope {
account = 'account',
  system = 'system'
}

export interface IUser {
  id: string
  'user_name': string
  'userName'?: string
  saml_username: string
  email: string
  'full_name': string
  'first_name': string
  firstName: string
  'last_name' : string
  lastName: string
  'access_scope': IAccessScope
  'primary_account_id': string
  tenants: ITenant[]
  accounts: IAccount[]
  accountId?: IAccount
  permissions: IPermission[]
  active?: boolean
  status?: string
  pending?: boolean
  phone?: string
  'office_location'?: string
  'sign_in_method': ISignInMethod
  'user_created'?: boolean
  'mfa_status'?: string
  'reset_mfa'?: boolean
  locale?: string
  'created_by'?: string
  description?: string
  'friendly_name'?: string
  'expiry_date'?: string
  'created_at'?: string
  'masked_api_key'?: string
  'user_type': string
  'can_manage_service_user': boolean
  'date_format'?: string
  'can_send_reset_password_email': boolean
  'can_send_cac_email': boolean
  'invite_std_user': boolean
  'can_resend_sso_email': boolean
  'can_unlock_with_mfa': boolean
  'can_unlock_with_password': boolean
  'can_edit_features': boolean
  'can_edit_root_user': boolean
  'can_edit_system_user': boolean
  'can_edit_account_user': boolean
  'default_app_url': string
  'has_permissions': boolean
  'oidc_username': string
  login?: string
  roleIds?: any
  applicationIds?: any
  roleName?: string
  type:string
}

export interface IPartialUser {
  id: string
  email: string
  name: string
}

export interface IAccounts {
  items: IAccount[]
  total: number
  isLoading: boolean
  isCreating: boolean
  isEditing: boolean
  isDeleting: boolean
}

export interface IAccount {
  id: string
  name: string
  active: boolean
  'created_at': string
  admins: IPartialUser[]
  tenant: ShortEntityResponse
  templates?: ShortEntityResponse[]
  profiles?: ShortEntityResponse[]
  'mfa_enabled': boolean
  locale: string
  'service_period': {
    from: string
    to: string
  }
  enabled_feature_ids: string[]
}

export interface ITenants {
  items: ITenant[]
  total: number
  isLoading: boolean
  isCreating: boolean
  isEditing: boolean
}

export interface ISigningProviders {
  items: ISigningProvider[]
}

export interface ITenant {
  id: string
  name: string
  active: boolean
  admins: IPartialUser[]
  mfa_enabled: boolean,
  friendly_identifier: string,
  from?: string
  actionsLabel?: string,
  endDate: string
  'service_period': {
    from: string
    to: string
  }
  'auth_config': IAuthConfig[]
  'sign_in_methods': ISignInMethod[]
  'oauth_clients': IOauthClient[]
  'has_dsm_license': boolean
  locale: string
  'system_account': boolean
  can_edit_mfa: boolean
}
export interface IAuthConfig {
    id: string
    status: string
    type: string
}

export interface IOauthClient {
  id: string
  oauthClientId: string
  oauthClientAccountId: string
  name: string
  status: string
}
export interface ISignInMethod {
  mfaStatus: string
  signInMethod: string
  status: string
  clientAuthCertLoginEnabled: boolean
}
export interface IFederation {
  id: string,
  name: string,
  status: string,
  type: string,
  standard_login: boolean,
  'account_id': string,
  'acs_url': string,
  'service_provider_config': IServiceProvider | undefined,
  isLoading: boolean
  'identity_provider_config': IIdp | undefined
}

export interface IServiceProvider {
  'acs_url': string,
  'entity_id': string,
  metadata: string
}
export interface IIdp {
	'metadata_url': string,
    metadata: {
       metadata: string
    }
    certificates: ICertificate[]
}

export interface IIdentityProvider {
  metadata: string,
  'metadata_url': string,
  certificates: ICertificate[]

}

export interface ICertificate {
  id?: string,
  certificate?: string,
  'common_name'?: string,
  thumb_print: string,
  'expiration_date'?: string
}
export interface IAdminProfile {
  isFetching: boolean
  data?: IUser
  permissions: Record<string, IPermission[]>
}

export interface IAdminUserProfile {
  data?: IUserProfile
}

export interface IUserApplication {
  data?: IApplication[]
}
export interface IUserProfile {
  access_scope: string
  accounts: IUserProfileAccount[]
  active: boolean
  applications: IApplication[]
  email: string
  first_name: string
  id: string
  last_name: string
  primary_account_id: string
  user_name: string
}

export interface IUserProfileAccount {
  id: string
  name: string
}

export interface IApplication {
  app_name: string
  app_code: string
  icon: string
  id: string
  name: string
  permissions: string[]
  url: string
  context: string
  short_app_code: string
}
export interface ILicenseData {
  data?: ILicense
}

export interface IFeatures {
  roles: boolean
}

export interface ILicense {
  id: string
  dev_license: boolean
  organization: string
  created_at: string
  expires_at: string
  version: string
  applications: ILicenseApp[]
  optional_features: string[]
  optional_applications: string[]
}

export interface ILicenseApp {
  id: string
  app_code: string
  name: string
  features: IFeature[]
}

export interface IFeature {
  name: string
  attributes: any
}

export enum NotificationTypes {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

export interface INotification {
  id: string
  message: string
  type: NotificationTypes
  title?: string
  duration?: number
  detailsErrorMessage?: string
  translateMessage?: boolean
}

export interface INotifications {
  items: INotification[]
}

export interface IOrganizations {
  items: IOrganization[]
  total: number
  isFetching: boolean
  isCreating: boolean
  isEditing: boolean
}

export interface IOrganization {
  id: string
  name: string
  tenant: ITenant
  account: IAccount
  active: boolean
  address?: string
  address2?: string
  'zip_code'?: string
  city?: string
  state?: string
  country?: string
  phone?: string
  actions?: string
}

export enum ContactType {
  technical = 'technical',
  corporate = 'corporate'
}

export interface IContact {
  id: string
  type: ContactType
  'first_name': string
  'last_name': string
  email: string
  'organization_id': string
  phone: string
  title: string
}

export interface IContacts {
  isFetching: boolean
  isCreating: boolean
  isEditing: boolean
  data: Record<string, IContact[]>
}

export interface ShortEntityResponse {
  id: string
  name: string
}

export interface ISeat {
  id: string
  email: string
  active: boolean
  account: ShortEntityResponse
  'seat_type': ShortEntityResponse
  'seat_name': string
  'seat_id': string
  phone?: string
}

export interface ISeatType {
  id: string
  type: string
  'display_name': string
  'licensed_amount': number
  'allocated_amount': number
  'pending_enrollment': number
}

export interface ISeats {
  items: ISeat[]
  total: number
  isFetching: boolean
  isDeleting: boolean
  isSeatCreated: boolean
  isSeatUpdated: boolean
  seatTypes: ISeatType[]
  seatCode: string
  systemSeatTypes: ISeatType[]
  accountSeatTypes: Record<string, IAccountSeatTypes>
  tenantSeatTypes: Record<string, ITenantSeatTypes>
}

export interface IAccountSeatTypes {
  tenantId: string
  accountId: string
  accountName: string
  seatTypes: Record<string, ISeatType>
}

export interface ITenantSeatTypes {
  tenantId: string
  tenantName: string
  seatTypes: Record<string, ISeatType>
}

export enum SeatTypesMap {
  UserSeat = 'USER_SEAT',
  DeviceSeat = 'DEVICE_SEAT'
}

export interface IEnrollment {
  fields: IEnrollmentFieldsResponse | {}
  profile: ISeatProfile | {}
  isFetching: boolean
}

export interface ICertificateAuthority {
  id: string
  'ca_id': string
  parent: ShortEntityResponse
  'cert_type': string
  'issue_end_entities': boolean
  'issue_ica_intermediates': boolean
  'trust_type': string
  'issue_types': string[]
  tenants: ShortEntityResponse[]
  accounts: ShortEntityResponse[]
  'common_name': string
  'valid_from': string
  'valid_to': string
  'ica_number': number
  'certificate_number': number
  name: string
}

export interface ICertificateAuthorities {
  items: ICertificateAuthority[]
  total: number
}

export interface ITemplateField {
  optional: boolean
  multiple: boolean
  source: string
  value: any
  min: number
  max: number
  key: string
  type: string
  input: TypeInputList
  section?: string
  'allowed_sources': string[]
  'possible_values': string[]
  'provided_by': string[]
}

export interface ITemplate {
  id: string
  name: string;
  'cert_type': string
  'trust_type': string
  'install_type': string
  'issue_types': string[]
  'template_json': string
  'seat_type': ShortEntityResponse
  'enrollment_approval_required': boolean
  active: boolean
  fields: ITemplateField[]
}

export interface ITemplates {
  items: ITemplate[]
  total: number
}

export interface IProfile {
  id: string
  name: string
  active: boolean
  tenant: ShortEntityResponse
  template: ShortEntityResponse
  ica: ShortEntityResponse
  accounts: ShortEntityResponse[]
  fields: ITemplateField[]
  authentication_method: string
  days_till_enrollment_expires: number
  enrollment_method: string
}

export interface IProfiles {
  items: IProfile[]
  profile?: IProfile
  total: number
  isFetching: boolean
}



export interface IManageEnrollment {
  created_at: string
  email: string
  expires_at: string
  id: string
  account: ShortEntityResponse
  profile: ShortEntityResponse
  seat: ShortEntityResponse
  status: string
}

export interface IManageEnrollments {
  isFetching: boolean
  items: IManageEnrollment[]
  total: number
}

export interface IApiToken {
  id: string
  name: string
  active: boolean
  enabled: boolean
  end_date: string
  start_date: string
  permissions: Record<string, string>[]
  user_id: string
  status?: string
  masked_api_key: string
  validity?: string
}

export interface IApiTokens {
  items: IApiToken[]
  total: number
  apiToken?: string
}

export interface IPermission {
  id: string
  name: string
  system_scope: boolean
  account_scope: boolean
  feature_name: string
}

export interface IClientAuthCerts {
  items: IClientAuth[],
  total: number
  clientAuthCert?: IClientAuthCert
}

export interface IClientAuth {
  id: string
  'common_name': string
  name: string
  'end_date': string
  'start_date': string
  enabled: boolean
  user_id: string
  'serial_number': string,
  'x509_cert': string,
  'enrollment_method': string

}

export interface IClientAuthCert {
  id: string
  name: string
  common_name: string
  expiration_date: string
  start_date: string
  x509_cert: string
  ca_cert: string
  enabled: boolean
  predefinedKeyStoreId: string
}

export interface DDCSettings {
  keystore_location: KeyStoreLocation
  allow_export_private_key: boolean
  hardware_token_type: HardwareTokenType[]
  internal_download: boolean
}

export enum KeyStoreLocation {
Browser = 'browser',
HardwareToken = 'hardware_token'
}

export const keystoreLocationList = [
{
key: KeyStoreLocation.Browser,
label: 'profiles.keystore.browser'
},
{
key: KeyStoreLocation.HardwareToken,
label: 'profiles.keystore.hardwareToken'
}
]

export enum HardwareTokenType {
ETOKEN = 'safe_net_e_token',
YUBIKEY = 'yubikey_nfc_5',
ALL = 'all'
}


export interface ICustomSettings {
  copyright: string
  logo: string
  show_powered_by: boolean
  urls: ICustomUrls

}

export interface ICustomUrls {
  contactUs: string
  legalRepository: string
  privacyPolicy: string
  support: string
  termsOfUse: string
  webtrustAudit: string
}