import { translate } from '../../../shared/helpers/utils'
import { FilterOption } from '@digicert/dcone-common-ui/components/es/components/table/filters/filters.types'

export enum AuditLogEventActions {
  FetchAuditEventsRequest = 'FETCH_AUDIT_EVENTS_REQUEST',
  FetchAuditEventsSuccess = 'FETCH_AUDIT_EVENTS_SUCCESS',
  FetchAuditEventsFailure = 'FETCH_AUDIT_EVENTS_FAILURE',
  FetchAuditEventRequest = 'FETCH_AUDIT_EVENT_REQUEST',
  FetchAuditEventSuccess = 'FETCH_AUDIT_EVENT_SUCCESS',
  FetchAuditEventFailure = 'FETCH_AUDIT_EVENT_FAILURE',
}

export const AuditLogActionItems = ():FilterOption<any>[] => {
  return [
    {
      value: 'CREATE',
      label: translate('common.form.buttons.createBtn') as string,
      func: 'eq'
    },
    {
      value: 'UPDATE',
      label: translate('common.form.buttons.updateBtn') as string,
      func: 'eq'
    },
    {
      value: 'DELETE',
      label: translate('common.form.buttons.deleteBtn') as string,
      func: 'eq'
    },
    {
      value: 'LOGIN',
      label: translate('common.form.login') as string,
      func: 'eq'
    },
    {
      value: 'LOGIN_ERROR',
      label: translate('common.form.loginError') as string,
      func: 'eq'
    },
    {
      value: 'LOGOUT',
      label: translate('common.form.logout') as string,
      func: 'eq'
    },
    {
      value: 'UPDATE_PASSWORD',
      label: translate('common.form.updatePassword') as string,
      func: 'eq'
    },
    {
      value: 'TERMS_AND_CONDITIONS',
      label: translate('common.form.termsAndConditions') as string,
      func: 'eq'
    },
    {
      value: 'SEND_RESET_PASSWORD',
      label: translate('common.form.sendResetPassword') as string,
      func: 'eq'
    }
  ]
}

export const AuditLogResourceTypeItems = ():FilterOption<any>[] => {
  return [
    {
      value: 'APIKEY',
      label: translate('reporting.auditEvents.resourceTypeTitles.APIKEY') as string,
      func: 'eq'
    },
    {
      value: 'ACCOUNT',
      label: translate('reporting.auditEvents.resourceTypeTitles.ACCOUNT') as string,
      func: 'eq'
    },
    {
      value: 'USER',
      label: translate('reporting.auditEvents.resourceTypeTitles.USER') as string,
      func: 'eq'
    },
    {
      value: 'SSO_SAML',
      label: translate('reporting.auditEvents.resourceTypeTitles.SSO_SAML') as string,
      func: 'eq'
    },
    {
      value: 'SSO_SAML_MFA',
      label: translate('reporting.auditEvents.resourceTypeTitles.SSO_SAML_MFA') as string,
      func: 'eq'
    },
    {
      value: 'SSO_OIDC',
      label: translate('reporting.auditEvents.resourceTypeTitles.SSO_OIDC') as string,
      func: 'eq'
    },
    {
      value: 'SSO_OIDC_MFA',
      label: translate('reporting.auditEvents.resourceTypeTitles.SSO_OIDC_MFA') as string,
      func: 'eq'
    },
    {
      value: 'BASIC_AUTH',
      label: translate('reporting.auditEvents.resourceTypeTitles.BASIC_AUTH') as string,
      func: 'eq'
    },
    {
      value: 'BASIC_AUTH_MFA',
      label: translate('reporting.auditEvents.resourceTypeTitles.BASIC_AUTH_MFA') as string,
      func: 'eq'
    },
    {
      value: 'SAML2_CONFIG',
      label: translate('reporting.auditEvents.resourceTypeTitles.SAML2_CONFIG') as string,
      func: 'eq'
    },
    {
      value: 'CLIENT_AUTH_CERT',
      label: translate('reporting.auditEvents.resourceTypeTitles.CLIENT_AUTH_CERT') as string,
      func: 'eq'
    },
    {
      value: 'CLIENT_AUTH_CERT_MFA',
      label: translate('reporting.auditEvents.resourceTypeTitles.CLIENT_AUTH_CERT_MFA') as string,
      func: 'eq'
    },
    {
      value: 'OAUTH_CLIENT',
      label: translate('reporting.auditEvents.resourceTypeTitles.OAUTH_CLIENT') as string,
      func: 'eq'
    },
    {
      value: 'OAUTH_CONFIG',
      label: translate('reporting.auditEvents.resourceTypeTitles.OAUTH_CONFIG') as string,
      func: 'eq'
    },
    {
      value: 'LICENSE',
      label: translate('reporting.auditEvents.resourceTypeTitles.LICENSE') as string,
      func: 'eq'
    },
    {
    value: 'DIGEST_AUTH',
    label: translate('reporting.auditEvents.resourceTypeTitles.DIGEST_AUTH') as string,
    func: 'eq'
    }
  ]
}

export const AuditLogStatusItems = ():FilterOption<any>[] => {
  return [
    {
      value: 'success',
      label: translate('common.statuses.success') as string,
      func: 'eq'
    },
    {
      value: 'failure',
      label: translate('common.statuses.failure') as string,
      func: 'eq'
    }
  ]
}