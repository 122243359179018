export enum DTATokenName {
  YUBIKEY = 'YUBIKEY',
  ETOKEN = 'ETOKEN',
  MACOS = 'MACOS',
  WINOS = 'WINOS'
}

export const DTAFriendlyName = {
  [DTATokenName.YUBIKEY]: 'Yubikey',
  [DTATokenName.ETOKEN]: 'eToken'
}

export enum DTAKeystoreType {
  Platform = 'Platform',
  HWToken = 'HWToken',
  SWToken = 'SWToken'
}

export enum DTAKeyStoreLocation {
  OSKeystore = 'OSKS',
  DigicertKeystore = 'DIGICERT_SOFTWARE',
  HardwareToken = 'HWTK'
}

export enum DTAHardwareTokenOptionsType {
  ETOKEN = 'ETOKEN',
  YUBIKEY = 'YUBIKEY',
  ANY = 'ANY'
}

export const DTAKeystoreLocationToKeystoreType = {
  [DTAKeyStoreLocation.OSKeystore]: DTAKeystoreType.Platform,
  [DTAKeyStoreLocation.DigicertKeystore]: DTAKeystoreType.SWToken,
  [DTAKeyStoreLocation.HardwareToken]: DTAKeystoreType.HWToken
}

export enum DTAYubikeySlot {
  Authentication = 'AUTHENTICATION',
  DigitalSignature = 'DIGITAL_SIGNATURE',
  KeyManagement = 'KEY_MANAGEMENT',
  CardAuthentication = 'CARD_AUTHENTICATION'
}

//time given to user to open DTA client wia prompt or manually
export const DTACreateTimeout = 15000

//delay before retry attempt
export const DTAInitializeRetryTimeout = 500
