import { ActionType, dispatcherActionType } from 'core/utils/interfaces'
import { AccountSignInRequestPayload, IdpMetadataRequestPayload } from './AccountSignIn.types'
import { actionErrorNotifier } from 'core/utils/helpers'
import { actionCoreApi } from 'core/utils/api'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import ActionsList from 'core/actions/Actions.list'
import { ITenant } from 'reducers/Reducers.interfaces'

export const createAccountSignIn = (payload: AccountSignInRequestPayload): dispatcherActionType =>
	actionCoreApi(
		ActionsList.CreateAccountSignIn,
		HTTPUrlHelper.createAccountSignInAPI(),
		'POST',
		payload )

export const getAccountSignIn = (id: string): dispatcherActionType =>
	actionCoreApi(
		ActionsList.CreateAccountSignIn,
		HTTPUrlHelper.getAccountSignInAPI(id),
		'GET')


export const createIdpMetadata = (payload: IdpMetadataRequestPayload, federationProviderId: string, isEditMode: boolean): ActionType => actionErrorNotifier(
	actionCoreApi(
		ActionsList.CreateIdpMetadata,
		HTTPUrlHelper.createIdpMetadataAPI(federationProviderId),
		'PUT',
		payload )
	, true,
	{
		title: '',
		message: !isEditMode ? 'notifications.authConfig.createAuthConfigSuccess' : 'notifications.authConfig.editAuthConfigSuccess'
	}
)


export const editStandardSignIn = (): dispatcherActionType =>
	actionCoreApi(
		ActionsList.CreateAccountSignIn,
		'',
		'GET')


export const disableSigninMethod = (account: ITenant, currentMethodBeingEdited: string): boolean => {
	const standardSignInMethod = account.sign_in_methods.find(item => item.signInMethod === 'standard')
    const standardSignInstatus = standardSignInMethod ? standardSignInMethod.status : 'disabled'
    const ssoMethodSaml = account.sign_in_methods.find(item => item.signInMethod === 'singleSignOnSaml')
    const ssoMethodOidc = account.sign_in_methods.find(item => item.signInMethod === 'singleSignOnOidc')

    const cacMethod = account.sign_in_methods[0].clientAuthCertLoginEnabled
    const ssoStatusSaml = ssoMethodSaml ? ssoMethodSaml.status : 'disabled'
    const ssoStatusOidc = ssoMethodOidc ? ssoMethodOidc.status : 'disabled'

	if (currentMethodBeingEdited === 'standard') {
		if (ssoStatusSaml === 'disabled' && ssoStatusOidc === 'disabled' && !cacMethod) {
			return true
		}
	}
	if (currentMethodBeingEdited === 'cac') {
		if (ssoStatusSaml === 'disabled' && ssoStatusOidc === 'disabled' && standardSignInstatus === 'disabled') {
			return true;
		}
	}
	if (currentMethodBeingEdited === 'ssoOidc') {
        if (ssoStatusSaml === 'disabled' && standardSignInstatus === 'disabled' && !cacMethod) {
            return true;
        }
    }
	return false
}