import HTTPUrlHelper from "shared/helpers/HTTPUrlHelper";
import { actionCoreApi } from "core/utils/api";
import ActionsList from "core/actions/Actions.list";
import { ActionType } from "core/utils/interfaces";

export const verify  = (userName: string, state: string, locale: string) : void => {
  fetch(HTTPUrlHelper.verifyUserNameAPI(userName.includes('+') ? userName.replace('+', encodeURIComponent('+')) : userName, state, locale), {
    method: 'get' 
}).then(response => response.json())
.then(data => {
  window.location.href = data
  });
}

export const fetchCustomLoginSettings = (): ActionType =>
actionCoreApi(
  ActionsList.UserNameExists,
  HTTPUrlHelper.fetchCustomLoginSettings(),
  'GET'
)
