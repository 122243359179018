import React, { memo, ReactElement, SyntheticEvent, useCallback } from 'react'
import { withRouter } from 'react-router'
import { Translate } from 'react-localize-redux'
import { NavLink } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { isString } from 'lodash-es'
import { BasePathConst } from 'configs/Path.configs'
import { BreadcrumbName, breadcrumbNameMap } from './Breadcrumbs.helpers'
import { TBreadcrumbProps } from './Breadcrumbs.interfaces'
import styles from '../SecureLayout.module.scss'

const AppBreadcrumb = (props: TBreadcrumbProps): ReactElement => {
  const { location, match, userId } = props

  const handleNavLinkClick = useCallback((event: SyntheticEvent, name: BreadcrumbName): void => {
    if (!isString(name) && name.disabled) {
      event.preventDefault()
    }
  }, [])

  const pathSnippets = location.pathname.split('/').filter(i => i)
  if(pathSnippets.includes('administrators') && pathSnippets.includes(userId)) {
    const back = document.referrer
    if (back.includes("client-auth") || back.includes("create-api-Token")) {
      return (
        <Breadcrumb className={styles.breadcrumbWrap} separator={''}>
          <a
            href={back.substring(0, back.lastIndexOf('/'))}
            className={styles.activeBreadcrumb}
          >
            {<Translate id='common.form.buttons.backBtn' />}
          </a>
        </Breadcrumb>
      )
    }
    return (
      <Breadcrumb className={styles.breadcrumbWrap} separator={''}>
        <a
          href={back}
          className={styles.activeBreadcrumb}
        >
          {<Translate id='common.form.buttons.backBtn' />}
        </a>
      </Breadcrumb>
    )
  }
  const breadcrumbItems = pathSnippets.map((item, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    const name = breadcrumbNameMap(match.params)[url]

    if (url === BasePathConst || !name) return null

    return (
      <Breadcrumb.Item key={url}>
        <NavLink
          onClick={(event) => handleNavLinkClick(event, name)}
          to={url}
          exact
          activeClassName={styles.activeBreadcrumb}
        >
          {isString(name) ? <Translate id={name} /> : <Translate id={name.label} />}
        </NavLink>
        {index < pathSnippets.length - 1 && <span className={styles.customSeparator}>/</span>}
      </Breadcrumb.Item>
    )
  })

  if (!location || !match) return <></>
  return (
    <Breadcrumb className={styles.breadcrumbWrap} separator={''}>
      {breadcrumbItems}
    </Breadcrumb>
  )
}

AppBreadcrumb.displayName = 'AppBreadcrumb'

// Expected 2 type arguments, but got 1.
// @ts-ignore
export default withRouter<any>(memo(AppBreadcrumb))
