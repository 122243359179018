import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { formatDate, formatDateWithTimestampAndTimezone, getEntityStatus, getManagerName, translate } from 'shared/helpers/utils'
import {faPencil} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { withPermissionsHOC } from 'core/hoc/permissionsHoc'
import { IAppState } from 'core/store/store'
import { fetchProfile, fetchUserProfile } from 'core/actions/Profile.actions'
import { defaultListParams, LIMIT_MAX, RouterConfig } from 'core/utils/constants'
import { BasePathConst } from 'configs/Path.configs'
import { IAccessScope, IOauthClient, ITenant } from 'reducers/Reducers.interfaces'
import { getLicense } from '../../license/License.actions'
import Organizations from './organizations/Organizations'
import {
  Accordion,
  AccordionTypes,
  Button,
  ButtonType,
  CardContainer,
  DetailStatus,
  JumpNav,
  ModalTypes,
  SideRail,
  Spinner,
  StandardModal,
  Tag,
  TagType,
  Tooltip
} from '@digicert/dcone-common-ui'
import { LicenseForm } from './license-form/LicenseForm'
import {
  createLicenseAllocationUpdateEvent,
  fetchTenantById,
  getLicenseLimitDetails,
  getLicenseLimitEvents,
  updateLicenseByApp
} from '../Tenants.actions'
import { fetchOrganizations } from 'secure-pages/organizations/Organizations.actions'
import {
  deleteAccountOauthClientsById,
  fetchAllOauthClients,
  updateAccountOauthConfig
} from 'secure-pages/tenants/create-account-signing-client/AccountSigningClient.actions'
import {
  AccordianSection,
  Applications,
  IAccordionItem,
  IFeatureInfo,
  ILicenseInfo,
  ITenantOwnProps,
  ITenantProps,
  ITenantStateProps,
  LicenseHistoryResponse,
  Sections
} from './Tenant.types'
import { SigningClientCards } from './signing-client-cards/SigningClientCards'
import styles from './Tenant.module.scss'
import { ApplicationPermissionMap } from 'core/selectors/Profile.selectors'
import SignInSettings from 'secure-pages/settings/signin-settings/SignInSettings'
import fileDownload from 'js-file-download'
import { ISort } from 'core/actions/Actions.interfaces'
import moment from 'moment-timezone'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import {LicenseCard} from './license-card/LicenseCard'
import {getFeatureByAccount} from './features/Features.actions'
import history from '../../../core/history/history'
import { EditButtonRoleTypes } from './license-card/LicenseCard.types'
import OptionalSignInSettings from '../../settings/optional-signin-settings/OptionalSignInSettings'
import Features from './features/Features'
import { AccountFeatures, SystemAccount } from 'core/constants'
import NoPermissionsPage from 'shared/components/no-permissions-page'
import OAuthIntegrations from './oauth-integrations/OAuthIntegrations'
import { ISigningProvider } from '../create-account-signing-client/AccountSigningClient.types'

const Tenant = (props: ITenantProps) => {
  const { license, permissions, fetchUserProfile, getFeatureByAccount, fetchOrganizations, organizations, getLicense, fetchTenantById, fetchAllOauthClients, getLicenseLimitDetails, tenant, profile, accessScope, userProfile, updateLicenseByApp, createLicenseAllocationUpdateEvent, getLicenseLimitEvents, deleteAccountOauthClientsById, updateAccountOauthConfig, fetchProfile } = props
  const { id: tenantId } = props.match.params
  const [isDeleteSigningClientModalVisible, setIsDeleteSigningClientModalVisible] = useState(false)
  const [signingClientID, setSigningClientID] = useState('')
  // the editMode/setEditMode below is really custom to licenses as it differs from standard inline editing
  const [editMode, setEditMode] = useState(null)
  const [editSignInSettings, setEditSignInSettings] = useState(false)
  const [licenses, setLicenses] = useState(new Array<ILicenseInfo>())
  const [licenseLoad, setLicenseLoad] = useState(true)
  const [currentLicense, setCurrentLicense] = useState(license)
  const [showSideRail, setShowSideRail] = useState(false)
  const [licenseHistoryDetailsResponse, setLicenseHistoryDetailsResponse] = useState(new Map<string, Array<LicenseHistoryResponse>>())
  const [account, setAccount] = useState(tenant)
  const [oauthClients, setOauthClients] = useState(new Array<IOauthClient>())
  const [userProfileState, setUserProfileState] = useState(userProfile)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [showFeatureSection, setShowFeatureSection] = useState(false)
  const [features, setFeatures] = useState(new Array<IFeatureInfo>())
  const [keyLockerEnabled, setKeyLockerEnabled] = useState(false)
  const [updateFeatures, setUpdateFeatures] = useState(false)
  const [systemAccount, setSystemAccount] = useState(false)
  const [platformOauthClients, setPlatformOauthClients] = useState<Array<ISigningProvider[]>>()

  const fetchFeaturesByAccount = () => {
    getFeatureByAccount(tenantId).then((response: any) => {
      if (response.length > 0) {
        const apps = response.map(feature => {return feature.appCode})
        const applications = apps.filter((item,
                                          index) => apps.indexOf(item) === index)
        const features = applications.map(appcode => {
          const application = userProfile?.applications.find(app => app.app_code === appcode)
          return {
            header: getManagerName(appcode, keyLockerEnabled),
            context: application?.context,
            appId: application?.id,
            appCode: appcode,
            defaultFeatures: response.map(feat => {
              if (feat.appCode === appcode && feat.defaultFeature) {
                return {
                  featureId: feat.featureId,
                  featureName: translate(`features.name.${feat.featureId}`) as string,
                  featureDetails: translate(`features.details.${feat.featureId}`) as string,
                  featureDisplayOrder: feat.displayOrder
                }
              }
            }).filter(val => val !== undefined),
            features: response.map(feat => {
              if (feat.appCode === appcode && !feat.defaultFeature) {
                const additionalInfo: Array<string> = []
                if (translate(`features.additionalInfo1.${feat.featureId}`) !== feat.featureId) {
                  additionalInfo.push(translate(`features.additionalInfo1.${feat.featureId}`) as string)
                }
                if (translate(`features.additionalInfo2.${feat.featureId}`) !== feat.featureId) {
                  additionalInfo.push(translate(`features.additionalInfo2.${feat.featureId}`) as string)
                }
                return {
                  featureId: feat.featureId,
                  featureName: translate(`features.name.${feat.featureId}`) as string,
                  featureDetails: translate(`features.details.${feat.featureId}`) as string,
                  featureEnabled: feat.enabled,
                  date: feat.endDate,
                  featureAdditionalInformation: additionalInfo,
                  errorMessage: translate(`features.error.${feat.featureId}`) !== feat.featureId ? translate(`features.error.${feat.featureId}`) as string : ''
                }
              }
            }).filter(val => val !== undefined)
          }
        })
        setShowFeatureSection(true)
        setFeatures(features)
      }
    })
  }

  useEffect(() => {
    if (!systemAccount) {
      fetchProfile().then((profileResponse: any) => {
        if (profileResponse) {
          setKeyLockerEnabled(profileResponse.accounts.filter(account => account.id === tenantId)[0].enabled_feature_ids.includes(AccountFeatures.STM_KEYLOCKER_ACCOUNT))
          if (profileResponse.access_scope !== IAccessScope.account) {
            fetchFeaturesByAccount()
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(updateFeatures) {
      fetchFeaturesByAccount()
    }
  },[updateFeatures])

  const getRemaining = (feature: any, data: any): string => {
    if (data) {
      const feat = data.find((f) => f.name.toLowerCase() === feature.name)
      if (feat) {
        return feat.attributes.remaining
      }
    }
    return '-'
  }

  const getFeatures = (features: any, data: any): any => {
    return features
      ? features.map((feature) => {
        const dataFeature = data ? data.find(
          (f) => f.name.toLowerCase() === feature.name.toLowerCase()) : '-'
        return {
          id: Math.random,
          featureId: feature.name,
          licenseType: translate('dashboardLicense.features.' + feature.name),
          remaining: getRemaining(feature, data),
          used: dataFeature && dataFeature !== '-'? dataFeature.attributes.used : '-',
          totalAllocated: dataFeature && dataFeature !== '-' ?
            dataFeature.attributes.limit === -1
              ? translate('dashboardLicense.unlimited')
              : data.find(
                (f) => f.name.toLowerCase() === feature.name.toLowerCase()
              ).attributes.limit
            : '-'
        }
      })
      : []
  }

  const checkViewPermission = (licenseApp: any, userApp: any): boolean => {
    let visible = false
    if (licenseApp && userApp) {
      if (licenseApp.app_code === Applications.CAManager) {
        visible =
          userApp.permissions.filter(
            (perm) => perm === ApplicationPermissionMap.canViewCMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.IOT) {
        visible =
          userApp.permissions.filter(
            (perm) => perm === ApplicationPermissionMap.canViewIOTLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.MPKI) {
        visible =
          userApp.permissions.filter(
            (perm) => perm === ApplicationPermissionMap.canViewEMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.SSM) {
        visible =
          userApp.permissions.filter(
            (perm) => perm === ApplicationPermissionMap.canViewSMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.DSM) {
        visible =
          userApp.permissions.filter(
            (perm) => perm === ApplicationPermissionMap.canViewDSMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.DAM) {
        visible =
          userApp.permissions.filter(
            (perm) => perm === ApplicationPermissionMap.canViewTMLicense
          )?.length > 0
      }
    }
    return visible
  }

  const checkManagePermission = (licenseApp: any, userApp: any): boolean => {
    let canEdit = false
    if (licenseApp && userApp) {
      if (licenseApp.app_code === Applications.CAManager) {
        canEdit =
          userApp.permissions.find(
            (perm) => perm === ApplicationPermissionMap.canManageCMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.IOT) {
        canEdit =
          userApp.permissions.find(
            (perm) => perm === ApplicationPermissionMap.canManageIOTLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.MPKI) {
        canEdit =
          userApp.permissions.find(
            (perm) => perm === ApplicationPermissionMap.canManageEMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.SSM) {
        canEdit =
          userApp.permissions.find(
            (perm) => perm === ApplicationPermissionMap.canManageSMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.DSM) {
        canEdit =
          userApp.permissions.find(
            (perm) => perm === ApplicationPermissionMap.canManageDSMLicense
          )?.length > 0
      }
      if (licenseApp.app_code === Applications.DAM) {
        canEdit =
          userApp.permissions.filter(
            (perm) => perm === ApplicationPermissionMap.canManageTMLicense
          )?.length > 0
      }
    }
    return canEdit
  }

  const defaultLicenses = (t: [ILicenseInfo], uProfile: any, license: any) => {
    uProfile.applications.map((app) => {
      const licenseApp = license.applications.find(
        (a) => a.name.toLowerCase() === app.name.toLowerCase()
      )
      const userApp = uProfile
        ? uProfile.applications.find((a) => a.id === app.id)
        : undefined
      const accountApp = uProfile.applications.find(
        (application) => application.app_name === 'Account'
      )
      const scope = uProfile.access_scope === IAccessScope.system
      if (licenseApp && userApp) {
        if (licenseApp.features) {
          t.push({
            id: licenseApp.app_code,
            title: licenseApp.name,
            data: getFeatures(
              licenseApp.features.filter(
                (feature) => feature.attributes !== undefined
              ),
              undefined
            ),
            visible: checkViewPermission(licenseApp, userApp),
            canEdit: accountApp
              ? scope &&
              accountApp.permissions.find(
                (perm) => perm === ApplicationPermissionMap.canManageAccount
              ) !== undefined &&
              checkManagePermission(licenseApp, userApp)
              : false
          })
        }
        setLicenses(t)
      }
    })
  }

  const getDetailStatusProps = (acc: ITenant): any => {
    return {
      content: [
        {
          name: translate('common.form.status') as string,
          value: <Tag type={getEntityStatus(acc.active) === 'active' ? TagType.ACTIVE : TagType.INACTIVE}>
            <Translate id={`common.statuses.${getEntityStatus(acc.active)}`} />
          </Tag>
        },
        {
          name: translate('accounts.table.id') as string,
          value: acc.id
        },
        {
          name: translate('common.form.startDate') as string,
          value: formatDate(acc.service_period.from, profile?.date_format as string)
        },
        {
          name: translate('common.form.endDate') as string,
          value: formatDate(acc.service_period.to, profile?.date_format as string)
        },
        {
          name: translate('profile.language') as string,
          value: acc.locale ? translate('profile.languages.' + acc.locale) as string : translate('profile.languages.en_US') as string
        }
      ]
    }
  }

  const getParentContainer = (): HTMLElement | Window => {
    return document.getElementById('content_wrap') || window
  }

  const spin = (): ReactElement => {
    return (
      <div className='spin-wrap'>
        <Spinner />
      </div>
    )
  }

  const hideSideRail = () => {
    setShowSideRail(false)
  }

  const openSideRail = () => {
    setShowSideRail(true)
  }

  const getLicenseLimitHistory = (): void => {
    setLicenseHistoryDetailsResponse(new Map<string, Array<LicenseHistoryResponse>>())
    const params: ISort = { limit: 10, offset: 0 }
    params.sort = [{
      columnKey: 'timestamp:desc'
    }]
    getLicenseLimitDetails(params, tenantId, moment.tz.guess()).then((response: any) => {
      const entries = Array.from(Object.entries(response).sort((a, b) => a[0] < b[0] ? 1 : -1))
      const map = new Map<string, Array<LicenseHistoryResponse>>()
      entries.forEach(entry => {
        map.set(entry[0], entry[1] as LicenseHistoryResponse[])
      })
      setLicenseHistoryDetailsResponse(map)
    })
  }

  const licenseHistoryContent = (data: Array<LicenseHistoryResponse>) => {
    const sections = new Map<string, Array<LicenseHistoryResponse>>()
    data.map(licenseData => {
      const timestamp = formatDateWithTimestampAndTimezone(licenseData.timestamp)
      if (sections && sections.get(timestamp)) {
        const val = sections.get(timestamp)
        if (val) {
          val.push(licenseData)
          sections.set(timestamp, val)
        }
      } else {
        const arr = new Array<LicenseHistoryResponse>()
        arr.push(licenseData)
        sections.set(timestamp, arr)
      }
    })
    const sectionContent = new Array<any>()

    sections.forEach((value, key) => {
      const actor = value[0].actor
      const appCode = value[0].app_code
      const temp = (
        <div key={Date.now()+'-'+Math.floor(Math.random()*LIMIT_MAX)}>
          <span className={styles.sideRailListTitle}>{key}</span>
          {profile?.access_scope === IAccessScope.system && <span className={styles.sideRailListSubTitle}>{actor} <Translate id={'common.updatedLicense'} />{getManagerName(appCode, keyLockerEnabled)}</span>}
          {profile?.access_scope === IAccessScope.account && <span className={styles.sideRailListSubTitle}><Translate id={'common.licensesUpdated'} /><Translate id={'permissionGroups.' + appCode.toUpperCase()} /></span>}
          <ul className={styles.sideRailList}>
            {value.map((licenseData, index) => {
              return (
                <div key={index}>
                  <li>{licenseData.quantity < 0 ? licenseData.quantity : '+' + licenseData.quantity.toString()} <Translate id={'dashboardLicense.features.' + licenseData.feature} /> <Translate id={'common.licenses'} /></li>
                </div>
              )
            })}
          </ul>
        </div>
      )
      sectionContent.push(temp)
    })
    return sectionContent
  }

  const getLicenseDataSection = (date: string, licenseHistorySectionList: Array<LicenseHistoryResponse>) => {
    const formattedDate = formatDate(date, profile?.date_format)
    const section = {
      headline: formattedDate,
      key: formattedDate,
      link: '#',
      anchorId: formattedDate
    }
    section['view'] = licenseHistoryContent(licenseHistorySectionList)
    return section
  }

  const licenseHistoryData = (): any => {
    const data = new Array<AccordianSection>()
    const dateMapper = new Map<string, Array<LicenseHistoryResponse>>()
    licenseHistoryDetailsResponse.forEach((value, key) => {
      const date = formatDate(key, profile?.date_format)
      if (dateMapper && dateMapper.get(date)) {
        const val = dateMapper.get(date)
        if (val) {
          const newVal = val.concat(value)
          dateMapper.set(date, newVal)
        }
      } else {
        dateMapper.set(date, value)
      }
    })
    dateMapper.forEach((value, key) => {
      data.push(getLicenseDataSection(key, value))
    })
    return data
  }

  const downloadCsv = (e) => {
    e.preventDefault()
    const headers = profile?.access_scope === IAccessScope.system ? [translate('common.manager'), translate('common.date'), translate('common.licenseType'), translate('common.change'), translate('common.newTotal'), translate('common.user')] : [translate('common.manager'), translate('common.date'), translate('common.licenseType'), translate('common.change'), translate('common.newTotal')]
    let csvContent = headers.join(',') + "\n"
    const params: ISort = { limit: LIMIT_MAX, offset: 0 }
    params.sort = [{
      columnKey: 'timestamp:desc'
    }]
    getLicenseLimitEvents(params, tenantId, moment.tz.guess()).then((response: any) => {
      if (response) {
        response.items.map(data => {
          const timestamp = formatDate(data.timestamp, profile?.date_format ? profile.date_format : 'DD-MMM-YYYY') + ' ' + formatDateWithTimestampAndTimezone(data.timestamp)
          csvContent += translate('permissionGroups.' + data.app_code.toUpperCase()) + ','
          csvContent += timestamp + ','
          csvContent += translate('dashboardLicense.features.' + data.feature) + ','
          csvContent += (data.quantity < 0 ? data.quantity : '+' + data.quantity.toString()) + ','
          csvContent += data.limit
          if (profile?.access_scope === IAccessScope.system) {
            csvContent += ',' + data.actor
          }
          csvContent += '\n'
        })
      }
      const fileName = account?.name + '_' + moment().format(profile?.date_format) + '.csv'
      fileDownload(csvContent, fileName)
    })
  }

  const getLicenseHistoryActiveKeys = () => {
    return licenseHistoryData().map((data) => data.headline)
  }

  const renderEditOption = () => {
    if (
      account &&
      permissions.canManageAccount &&
      accessScope === IAccessScope.system
    ) {
      return (
        <Button role='link' buttonType={ButtonType.PRIMARY} id='editAccountBtn' onClick={() => history.push(`${BasePathConst}/accounts/${account.id}/edit`)}>
          <span className={styles.editAccountButton}>
            <FontAwesomeIcon size='lg' icon={faPencil} />
          </span>
        </Button>
      )
    }
  }

  const navigateToManageOrg = () => {
    history.push(RouterConfig.organizations.table)
  }

  const renderManageOrgBtn = (): ReactNode => {
    if (
      account &&
      permissions &&
      permissions.canManageOrganization
    ) {
      return (
        <div className={styles.button}>
          <Button buttonType={ButtonType.SECONDARY} id='manageOrgBtn' onClick={navigateToManageOrg} role='link'>
            <Translate id='organizations.manageOrganizations' />
          </Button>
        </div>
      )
    }
  }

  const navigateToCreateOrganization = () => {
    history.push(RouterConfig.organizations.create)
  }

  const renderCreateOrgBtn = () => {
    if (account && permissions && permissions.canManageOrganization) {
      return (
        <Button buttonType={ButtonType.SECONDARY} id='createOrgBtn' onClick={navigateToCreateOrganization}>
          <Translate id='organizations.createBtn'/>
        </Button>
      )
    }
  }

  const renderOrganization = (): ReactElement => {
    if (!account) return <></>

    const props = {
      tenantId: account.id,
      tenantName: account.name,
      active: account.active,
      accessScope: accessScope
    }

    return (
      <>
        {organizations?.length === 0 ? (renderCreateOrgBtn()) : renderManageOrgBtn()}
        <Organizations {...props}/>
      </>
    )
  }

  const renderLicenseFormTitle = () => {
    if (keyLockerEnabled && editMode === 'secure_software_manager') {
      return translate('dashboardLicense.applicationsUpdateTitle.keylocker') as string
    } else {
      return translate('dashboardLicense.applicationsUpdateTitle.'+editMode) as string
    }
  }

  const onEditClick = (id, data): any => {
    if (id === null && data.data) {
      const temp = Object.assign(new Array<ILicenseInfo>(), licenses)
      const app = temp.map(license => {
        if (license.id === data.app_code) {
          license.data.map(feat => {
            const feature = data.data.features.find(val => val.name.toLowerCase() === feat.featureId.toLowerCase())
            if (feature) {
              feat.remaining = feature.attributes.remaining
              feat.totalAllocated = feature.attributes.limit
            }
            return feat
          })
        }
        return license
      })
      setLicenses(app)
      setTimeout(() => {
        getLicenseLimitHistory()
      }, 1000)
    }
    setEditMode(id)
  }

  const renderLicenseForm = (): ReactElement => {
    const appFeature = licenses.find(
      (license) => license.id === editMode
    )
    let appContext
    appContext = userProfile?.applications
    .map((app) => {
      if (app.app_name !== 'Account') {
        return {
          context: app.context,
          appCode: app.app_code
        }
      }
    })
    .filter((context) => context !== undefined)
    if (editMode !== null && appFeature) {
      return (
        <LicenseForm
          onCancelClick={onEditClick}
          title={getManagerName(editMode, keyLockerEnabled)}
          dataSource={appFeature.data}
          updateLicenseByApp={updateLicenseByApp}
          createLicenseAllocationUpdateEvent={createLicenseAllocationUpdateEvent}
          context={
            appContext.find(
              (context) => context.appCode === editMode
            ).context
          }
          id={tenantId}
          license={currentLicense}
          app_code={editMode as unknown as string}
        />
      )
    }
    return <></>
  }

  const renderLicense = (): ReactElement => {
    return (
      <section className={styles.licenses}>
        {licenseHistoryDetailsResponse.size > 0 &&
        <div className={styles.licenseButton}>
          <Button buttonType={ButtonType.SECONDARY} onClick={openSideRail}><Translate id={'common.viewHistory'} /></Button>
        </div>
        }

        <CardContainer>
          {licenses.map((item, index) => {
            const renderApplicationName = () => {
              return getManagerName(item.id, keyLockerEnabled)
            }

            if (currentLicense && item.visible && item.data !== undefined) {
              return (
                <LicenseCard
                  key={'licence-card-'+index}
                  data={item.data}
                  canEdit={item.canEdit}
                  id={item.id}
                  onEditClick={() => {onEditClick(item.id, item.data)}}
                  title={renderApplicationName()}
                  editButtonRole={EditButtonRoleTypes.LINK}
                />
              )
            }
          })}
        </CardContainer>
      </section>
    )
  }

  const onMouseEnter = () => {
    if (platformOauthClients && platformOauthClients[0].length == 0) {
      setTooltipVisible(!tooltipVisible)
    } else {
      setTooltipVisible(false)
    }
  }

  const onBtnMouseLeave = () => {
    setTooltipVisible(false)
  }

  const signingBtnClick = (e) => {
    e.preventDefault()
    props.history.push(
      RouterConfig.accounts.createAccountSigningClient(account?.id)
    )
  }

  const renderDocumentSigningClientsBtn = () => {
    let disable = platformOauthClients && platformOauthClients[0].length == 0
      if(platformOauthClients) {
        disable = platformOauthClients[0].every(elem => elem.status === 'DISABLED')
      }
    if (account && permissions.canManageAccount) {
      return (
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onBtnMouseLeave}
        >
          <Button
            buttonType={ButtonType.SECONDARY}
            disabled={disable}
            id='manageDocumentSigningClientBtn'
            onClick={signingBtnClick}
            role='link'
          >
            <Translate id='signingService.addButton' />
          </Button>
          <Tooltip
            placement="right"
            title={translate('signingService.tooltip') as string}
            visible={tooltipVisible}
          />
        </div>
      )
    }
  }

  const closeDeleteModal = (): void => {
    setIsDeleteSigningClientModalVisible(false)
  }

  const openDeleteModal = (): void => {
    setIsDeleteSigningClientModalVisible(true)
  }

  const setDeletedClientId = (id) => {
    setSigningClientID(id)
  }

  const onClientEdit = (id: string) => {
    if (account) {
      props.history.push(
        RouterConfig.accounts.editAccountSigningClient(account.id, id)
      )
    }
  }

  const onEnableDisableClient = async (
    id: string,
    option: boolean
  ): Promise<void> => {
    const oauth_client = oauthClients
      ? oauthClients.find((client) => client.id === id)
      : undefined
    if (oauth_client) {
      const payload = {
        oauth_client_account_id: oauth_client.oauthClientAccountId,
        enabled: option
      }
      const stage = option ? 'enabled' : 'disabled'
      await updateAccountOauthConfig(
        payload,
        tenantId,
        oauth_client.oauthClientId,
        id,
        stage
      )
      const tenant: any = await fetchTenantById(
        tenantId
      )
      if (tenant) {
        setAccount(tenant)
        setOauthClients(tenant.oauth_clients)
      }
    }
  }

  const renderSigningClientCards = (): ReactNode => {
    if (
      oauthClients &&
      permissions &&
      permissions.canViewAccount
    ) {
      return (
        <SigningClientCards
          clients={oauthClients}
          closeDeleteModal={closeDeleteModal}
          openDeleteModal={openDeleteModal}
          setDeletedClientId={setDeletedClientId}
          onClientEdit={onClientEdit}
          onEnableDisable={onEnableDisableClient}
          enableActions={permissions.canManageAccount}
        />
      )
    }
  }

  const renderDocumentSigningClients = (): ReactElement => {
    if (!account) {
      return (
        <div className='spin-wrap'>
          <Spinner />
        </div>
      )
    }

    return (
      <>
        <p>
          <Translate id='signingService.providerText' />
        </p>
        {renderDocumentSigningClientsBtn()}
        {renderSigningClientCards()}
      </>
    )
  }

  const renderFeatures = (): ReactElement => {
    return (
      <Features
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile={profile!}
        id={tenantId}
        features={features}
        accountEndDate={account?.service_period.to}
        setUpdateFeatures={setUpdateFeatures}/>
    )
  }

  const deleteClient = async (): Promise<void> => {
    const oauth_client = oauthClients
      ? oauthClients.find((client) => client.id === signingClientID)
      : undefined
    setIsDeleteSigningClientModalVisible(false)
    if (oauth_client) {
      await deleteAccountOauthClientsById(
        tenantId,
        oauth_client.oauthClientId,
        signingClientID
      )
      const tenant: any = await fetchTenantById(
        tenantId
      )
      if (tenant) {
        setAccount(tenant)
        setOauthClients(tenant.oauth_clients)
      }
    }
  }

  const onEditSignInSettings = useCallback(
    (editing) => {
      setEditSignInSettings(editing)
    },
    []
  )

  const renderOauthIntegrations = () => {
    return (
      <OAuthIntegrations accountId={tenantId} canManageAccount={permissions && permissions.canManageAccount}/>
    )
  }

  const getAccordionData = (): IAccordionItem[] => {
    const tempAccordionData: IAccordionItem[] = []
    const ssoMethodSaml = account?.sign_in_methods.find(item => item.signInMethod === 'singleSignOnSaml')
    const ssoMethodOidc = account?.sign_in_methods.find(item => item.signInMethod === 'singleSignOnOidc')
    const ssoEnabled = (ssoMethodSaml !== undefined && ssoMethodSaml?.status == 'enabled') || (ssoMethodOidc !== undefined && ssoMethodOidc?.status == 'enabled')

    if (permissions && permissions.canViewOrganization) {
      tempAccordionData.push({
        headline: translate('organizations.title') as string,
        key: Sections.Organizations,
        view: renderOrganization(),
        link: '#',
        anchorId: Sections.Organizations,
        disablePanel: editMode !== null || editSignInSettings
      })
    }

    if (currentLicense && currentLicense.version !== '1.0') {
      tempAccordionData.push({
        headline:
          editMode !== null
            ? renderLicenseFormTitle()
            : (translate('common.form.licenses') as string),
        key: Sections.License,
        view:
          editMode !== null
            ? renderLicenseForm()
            : !licenseLoad
              ? renderLicense()
              : spin(),
        link: '#',
        anchorId: Sections.License,
        notCollapsible: editMode !== null,
        disablePanel: editSignInSettings
      })
    }

    if (account && showFeatureSection) {
      tempAccordionData.push({
        headline: translate('features.title') as string,
        key: Sections.Features,
        view: renderFeatures(),
        link: '#',
        anchorId: Sections.Features,
        disablePanel: editMode !== null || editSignInSettings
      })
    }

    {account && (
      tempAccordionData.push({
        headline: translate('authConfig.title') as string,
        key: Sections.AccountSignIn,
        view: <>
          <SignInSettings
            tenant={account}
            canEdit={permissions.canManageAccount}
            accountDetailsPage={true}
          />
          {ssoEnabled && (account && account.has_dsm_license) && (<OptionalSignInSettings tenant={account} editMode={editSignInSettings} onEdit={onEditSignInSettings} profile={profile}/>)}
        </>,
        link: '#',
        anchorId: Sections.AccountSignIn,
        disablePanel: editMode !== null
      })
    )}


    if (permissions && permissions.canViewAccount) {
      tempAccordionData.push(
        {
          headline: translate('oauthIntegration.integrationHeadline') as string,
          key: Sections.Integrations,
          view: renderOauthIntegrations(),
          link: '#',
          anchorId: Sections.Integrations,
          disablePanel: editMode !== null || editSignInSettings
        }
      )
    }
    if (account && account.has_dsm_license) {
      tempAccordionData.push({
        headline: translate('signingService.title') as string,
        key: Sections.DocumentSigningClients,
        view: renderDocumentSigningClients(),
        link: '#',
        anchorId: Sections.DocumentSigningClients,
        disablePanel: editMode !== null || editSignInSettings
      })
    }

    return tempAccordionData
  }

  const getAccordionActiveKeys = () => {
    return getAccordionData().map((data) => data.key)
  }

  const getJumpNavData = () => {
    return getAccordionData().map((data) => {
      return {
        url: `#${data.key}`,
        title: data.headline
      }
    })
  }

  useEffect(() => {
    if (permissions && permissions.canViewOrganization) {
      fetchOrganizations(defaultListParams, { account: tenantId }).then()
    }

    fetchUserProfile(false).then((uProfile: any) => {
      setUserProfileState(uProfile)
      getLicense().then((res: any) => {
        if (res) {
          setCurrentLicense(res)
          const t: [ILicenseInfo] = [{
            id: '',
            title: '',
            data: [{
              id: 0,
              featureId: '',
              licenseType: '',
              remaining: 0,
              used: '',
              totalAllocated: 0,
              licenseAllocation: ''
            }],
            canEdit: false,
            visible: false
          }]
          setLicenses(t)
          if (uProfile && res.version !== '1.0') {
            defaultLicenses(t, uProfile, res)
          }
        }
        setLicenseLoad(false)
      })
    })

    fetchTenantById(tenantId).then((tenant: any) => {
      if (tenant) {
        if (tenant.name === SystemAccount) {
          setSystemAccount(true)
        } else {
          getLicenseLimitHistory()
        }

        setAccount(tenant)
        setOauthClients(tenant.oauth_clients)
      } else {
        return setSystemAccount(true)
      }
    })

    if (permissions && permissions.canManageAccount) {
      fetchAllOauthClients().then((res: Array<ISigningProvider[]>) => {
        setPlatformOauthClients(res)
      })
    }
  }, [])

  useEffect(()=> {
    if (userProfileState && currentLicense && licenses.length > 1) {
      let temp = Object.assign(new Array<ILicenseInfo>(), licenses)
      userProfileState.applications.map((app) => {
        const licenseApp = currentLicense.applications.find(
          (a) => a.name.toLowerCase() === app.name.toLowerCase()
        )
        const userApp = userProfileState
          ? userProfileState.applications.find((a) => a.id === app.id)
          : undefined
        const accountApp = userProfileState.applications.find(
          (application) => application.app_name === 'Account'
        )
        const scope = userProfileState.access_scope === IAccessScope.system
        if (licenseApp && userApp ) {
          if (app.app_code !== Applications.AM && app.context) {
            fetch(HTTPUrlHelper.getAccountLicenseByApp(app.context, tenantId))
            .then((response) => {
              if (response.ok) {
                return response.json()
              }
            })
            .then((data) => {
              if (data && licenseApp) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                temp = temp!
                .map((application) => {
                  if (application.title) {
                    if (
                      application.title.toLowerCase() === (app.name.toLowerCase())
                    ) {
                      return {
                        id: licenseApp.app_code,
                        title: licenseApp.name,
                        data: getFeatures(
                          licenseApp.features.filter(
                            (feature) => feature.attributes !== undefined
                          ),
                          data.features
                        ),
                        visible: checkViewPermission(
                          licenseApp,
                          userApp
                        ),
                        canEdit: accountApp
                          ? scope &&
                          accountApp.permissions.find(
                            (perm) =>
                              perm ===
                              ApplicationPermissionMap.canManageAccount
                          ) !== undefined &&
                          checkManagePermission(licenseApp, userApp)
                          : false
                      }
                    }
                  }
                  return application
                })
                setLicenses(temp)
              }
            })
          }
        }
      })
    }
  }, [licenseLoad])

  useEffect(() => {licenseHistoryData()}, [licenseHistoryData, licenseHistoryDetailsResponse])

  if (!account) {
    if(systemAccount) {
      return <NoPermissionsPage />
    }
    return spin()
  }

  if (account && account.name === SystemAccount) {
    return <NoPermissionsPage />
  }

  return (
    <div id='account_detail_page' className={styles.accountDetail}>
      <div className={styles.headerStatusWrap}>
        <div className={styles.container}>
          <h1 className={styles.title}>
            <Translate id='breadcrumb.details.account' />
            {`: ${account.name}`}
          </h1>
        </div>
        <DetailStatus {...getDetailStatusProps(account)} />
      </div>

      <div className={styles.sectionsSideNavWrap}>
        <div className={styles.sections}>
          {!licenseLoad && currentLicense && (
            <Accordion
              defaultActiveKey={getAccordionActiveKeys()}
              oneByOne={false}
              accordionData={getAccordionData()}
            />
          )}
        </div>
        <div className={styles.sideNav}>
          <JumpNav
            headline={translate('common.pageNavTitle') as string}
            actions={renderEditOption()}
            bounds={100}
            displayHash={false}
            topSpacing={10}
            getScrollContainer={getParentContainer}
            items={getJumpNavData()}
          />
        </div>
      </div>

      <SideRail
        headline={account.name + ' ' + translate('common.licenseHistory')}
        show={showSideRail}
        onDismiss={hideSideRail}
        className={styles.sideRail}
      >
        <div className={styles.sideRailRecentHistory}>
          <h3>
            <Translate id={'common.recentHistory'} />
          </h3>
        </div>
        <div className={styles.sideRailAction}>
          <Button buttonType={ButtonType.SECONDARY} onClick={downloadCsv}>
            <Translate id={'common.downloadCsv'} />
          </Button>
        </div>

        {licenseHistoryDetailsResponse.size > 0 &&
        <Accordion
          showArrow={false}
          type={AccordionTypes.BORDERED}
          oneByOne={false}
          defaultActiveKey={getLicenseHistoryActiveKeys()}
          accordionData={licenseHistoryData()}
        />
        }
      </SideRail>

      {isDeleteSigningClientModalVisible && (
        <StandardModal
          headline={translate('signingService.modalTitle') as string}
          display={isDeleteSigningClientModalVisible}
          onOkCallBack={deleteClient}
          onCancelCallBack={closeDeleteModal}
          modalType={ModalTypes.DESTRUCTIVE}
          buttonLabel={
            translate('common.form.buttons.deleteSigningClient') as string
          }
          buttonCancelLabel={
            translate('common.form.buttons.cancelBtn') as string
          }
        >
          <Translate id='signingService.modalText' />
        </StandardModal>
      )}
    </div>
  )
}

export default connect(
  (state: IAppState, ownProps: ITenantOwnProps): ITenantStateProps => {
    const tenantId = ownProps.match.params.id
    return {
      tenant: state.tenants.items.find((item) => item.id === tenantId),
      accessScope: state.profile.data?.access_scope,
      providers: state.accountSigningClient.items,
      userProfile: state.userProfile.data,
      license: state.license.data,
      profile: state.profile.data,
      organizations: state.organizations.items
    }
  },
  {
    fetchTenantById,
    fetchOrganizations,
    updateAccountOauthConfig,
    deleteAccountOauthClientsById,
    fetchAllOauthClients,
    updateLicenseByApp,
    getLicense,
    fetchUserProfile,
    fetchProfile,
    createLicenseAllocationUpdateEvent,
    getLicenseLimitEvents,
    getLicenseLimitDetails,
    getFeatureByAccount
  }
)(withPermissionsHOC(Tenant, ['canViewAccount']))