import { get } from 'lodash-es'
import { detect } from 'detect-browser'
import { toBase64 } from 'pvutils'

import { PKIClientInstance } from 'core/services/dta/DTAClientInstane'
import {
  DTAHardwareTokenOptionsType,
  DTAKeyStoreLocation,
  DTAKeystoreLocationToKeystoreType,
  DTAKeystoreType,
  DTATokenName
} from 'core/services/dta/dta.constants'
import { Certificate, Token } from 'core/services/dta/DTAClient'
import { translate } from './Translation.helpers'

export const DTACertNotFoundError = 'dta.error.certificateNotFound.description'
export const DTACertSignError = 'dta.error.failedToSign.description'
export const DTACSRGenerationError = 'dta.error.failedToGenerateCSR.description'
export const DTAYubikeySlotNotSpecified = 'dta.error.yubikeySlotNotSpecified.description'

export const isDTAHardwareTokenProfile = true

export const isDTADigiCertKeystoreProfile = false

export const isAllowedExportPrivateKey = true

export const getCurrentOSDTAKeyStoreId = (): string => {
  const browser = detect()
  const os = get(browser, 'os')

  if (os) {
    if (os.includes('Mac')) return DTATokenName.MACOS
    if (os.includes('Win')) return DTATokenName.WINOS
  }

  throw 'No keystore for current browser or platform'
}

export const getDTATokenId = (keystoreLocation: string): string => {
  return keystoreLocation === DTAKeyStoreLocation.DigicertKeystore
    ? 'DCSWKS-0'
    : getCurrentOSDTAKeyStoreId() + '-0'
}

const getProfileSignAlgorithm = (signAlgorithm: string): string => {
  if (signAlgorithm.includes('RSA')) {
    return signAlgorithm + 'ENCRYPTION'
  }

  if (signAlgorithm.includes('ECDSA')) {
    const [hashFunction] = signAlgorithm.split('With')
    return 'ECDSAWith' + hashFunction
  }

  throw 'Can not match signing algorithm'
}

export const getHardwareTokens = async (): Promise<Token[]> => {
  const client = await PKIClientInstance.get()
  await client?.refreshTokens()

  const allTokensTokens = await client?.getTokens()

  return allTokensTokens ? allTokensTokens.filter(token => token.type === 'HWToken') : []
}

export const getSoftwareTokens = async (): Promise<Token[]> => {
  const client = await PKIClientInstance.get()
  await client?.refreshTokens()

  const allTokensTokens = await client?.getTokens()

  return allTokensTokens ? allTokensTokens.filter(token => token.type === 'SWToken') : []
}

export const getDCKeyStoreToken = async (): Promise<Token | undefined> => {
  const softwareTokens = await getSoftwareTokens()

  return softwareTokens.find(token => token.id === 'DCSWKS-0')
}

export const initDCKSToken = async (tokenId: string, soPIN: string, userPIN, label: string): Promise<any> => {
  const client = await PKIClientInstance.get()

  await client?.initToken(tokenId, soPIN, userPIN, label)
}

export const getTokensBasedOnProfileSettings = async (): Promise<Token[]> => {
  const hardwareTokens = await getHardwareTokens()

  const allowedTokenType = DTAHardwareTokenOptionsType.ANY

  return allowedTokenType.includes(DTAHardwareTokenOptionsType.ANY)
    ? hardwareTokens
    : hardwareTokens.filter(token => allowedTokenType.some(allowedType => token.keyStoreId?.includes(allowedType)))
}

const getKeystoreTypeFromProfile = (profile: IProfile): DTAKeystoreType => {
  const keystoreLocation = get(profile, 'digicert_trust_assistant_settings.keystore_location')

  return DTAKeystoreLocationToKeystoreType[keystoreLocation]
}

export const findCertBySerialNumber = async (serialNumber: string, profile: IProfile): Promise<Certificate> => {
  const client = await PKIClientInstance.get()
  const allCertificates = await client?.getCertificates()
  const normalizedSerialNumber = serialNumber.toLowerCase()

  const keystoreType = getKeystoreTypeFromProfile(profile)

  const certificates = allCertificates?.filter(
    cert => cert.serial?.toLowerCase() === normalizedSerialNumber && cert.keyStoreType === keystoreType
  )

  if (!certificates || !certificates.length) {
    throw translate(DTACertNotFoundError, { serialNumber: serialNumber })
  }

  return certificates[0]
}

export const generateCSR = async (keyStoreId): Promise<string> => {
  const client = await PKIClientInstance.get()
  let options = {}

   if (keyStoreId.includes(DTATokenName.YUBIKEY)) {

     //options['slot'] = startCase(camelCase(profile.digicert_trust_assistant_settings.yubikey_slot)).replace(/ /g, '')
   }

  const signAlgorithm = 'sha256WithRSAENCRYPTION'

  const [keyType, keySize] = ['RSASSA-PKCS1-v1_5', '2048']

  const csr = await client?.generate(keyStoreId, 'PKCS10', keyType, keySize, signAlgorithm, false, options)

  if (!csr) {
    throw translate(DTACSRGenerationError)
  }

  return csr
}

export const getSignAlgoFormat = (signAlgo: string): string => {
  const algo = signAlgo.toLowerCase()

  if (algo.includes('rsaencryption')) {
    return 'RSASSA-PKCS1-v1_5'
  } else if (algo.includes('rsassapss')) {
    return 'RSASSA-PSS'
  } else if (algo.includes('ecdsa')) {
    return 'ECDSA'
  } else {
    throw new Error(`Algorithm ${signAlgo} not supported`)
  }
}
export const importCert = async (keyStoreId, certificateBody: string) => {
  const client = await PKIClientInstance.get()

  const options = {}
  let importCA = []

   if (keyStoreId.includes(DTATokenName.YUBIKEY)) {
     //options['slot'] = startCase(camelCase(profile.digicert_trust_assistant_settings.yubikey_slot)).replace(/ /g, '')
   }

  const preparedCertBody = toBase64(certificateBody)

  if (!keyStoreId.includes(DTATokenName.YUBIKEY)) {
    const parsedCert = await client?.parseFile(preparedCertBody, '')
    importCA = parsedCert?.certs ? parsedCert.certs.map(cert => cert.serial) : []
  }

  //const extractable = isAllowedExportPrivateKey(profile)

  await client?.importCert(
    keyStoreId as string,
    'X509',
    preparedCertBody,
    '',
    importCA,
    false,
    options
  )
}


