
export default {
  expire_date: {
    type: 'datepicker'
  },
  email: {
    required: true
  }
}

export enum InputTypes {
  number = 'input_number',
  text = 'input',
  date = 'datepicker',
  select = 'select_box'
}
